import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components'
import { device } from "../media"

const NewsSlider = () => {
    const themeContext = useContext(ThemeContext);
    return (
        themeContext.newsSlider.image ?
            <Wrapper>
                <Content>
                    {
                        themeContext.newsSlider.link ?
                            <a href={themeContext.newsSlider.link} target="_blank"><Image src={themeContext.newsSlider.image} /></a>
                            :
                            <Image src={themeContext.newsSlider.image} />
                    }
                </Content>
            </Wrapper>
            :
            <>
            </>
    )
}

export default NewsSlider

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
    @media ${device.mobile}{
        height: auto;
    } 
`

const Content = styled.div`
    width: 960px;
    @media ${device.tablet}{
        width: 820px;
    }
    @media ${device.mobile}{
        width: 100%;
        border-radius: 0;
    } 
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
`