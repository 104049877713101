import styled from 'styled-components'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Header from '../components/Header'
import ReaderHeader from '../reader/ReaderHeader'
import ToolbarBottom from '../reader/ToolbarBottom'
import Navigation from '../components/Navigation'
import BrandingArea from '../components/BrandingArea'
import {device} from "../media"
import NewsSlider from '../components/NewsSlider';
import Div100vh from 'react-div-100vh';

export const MainLayout = props => (
        <Div100vh>
            <Header/>
            <BrandingArea/>
            <NewsSlider/>
            <Body> 
                <ContentArea>
                    <Content>
                      <Navigation/>
                      {props.children}
                    </Content>
                </ContentArea>
            </Body>
        </Div100vh>
  )

export const OverviewLayout = props => (
        <Div100vh>
            <Header/>
            <BrandingContainer>
              <BrandingArea/>
            </BrandingContainer>
            
            <NewsSlider/>
            <Body> 
                <ContentArea>
                    
                    <Content>
                      <Navigation/>
                      {props.children}
                    </Content>
                </ContentArea>
            </Body>
        </Div100vh>
  )
  
export const ReaderLayout = (props: { match: any, children: any}) => {
  const fullscreen = useFullScreenHandle();
  return (
    <Div100vh>
      <FullScreenContainer handle={fullscreen}>
          <ReaderHeader editionId={props.match.params.id}/>
          <ReaderBody>
              {props.children}

          </ReaderBody>
          <ToolbarBottom fullscreenHandle={fullscreen}/>
          
              
      </FullScreenContainer>
    </Div100vh>
  )

}

export const CourseLayout = props => {
  const fullscreen = useFullScreenHandle();
  return (
    <Div100vh>
      <FullScreenContainer handle={fullscreen}>
          <ReaderHeader/>
          <CourseBody>
              {props.children}

          </CourseBody>
          <ToolbarBottom fullscreenHandle={fullscreen}/>
          
              
      </FullScreenContainer>
    </Div100vh>
  )

}

export const MediaLayout = props => {
  const fullscreen = useFullScreenHandle();
  return (
    <Div100vh>
      <FullScreenContainer handle={fullscreen}>
          <ReaderHeader/>
          <MediaBody>
              {props.children}

          </MediaBody>
          
              
      </FullScreenContainer>
    </Div100vh>
  )
}

export const KioskOverviewLayout = props => (
  <Div100vh>
      <Header/>
      <BrandingArea/>
      <Body> 
          <ContentArea>
              <Content>
                {props.children}
              </Content>
          </ContentArea>
      </Body>
  </Div100vh>
)

const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 1;
`
const ContentArea = styled.div`
  display: flex;
  width: 960px;
  flex-direction: column;
  @media ${device.tablet}{
    width: 820px;
  }
  @media ${device.mobile}{
      width: 90%;
  }
`

const Content = styled.div`
  display: flex;  
` 

const BrandingContainer = styled.div`
  @media ${device.mobile}{
      display: none;
  }
`

const ReaderBody = styled.div`
  display: flex;
  width: 100%;
  
  justify-content: center;
  height: calc(100% - 152px);
  @media ${device.mobile}{
        height: calc(100% - 118px);
    }

`

const CourseBody = styled.div`
  display: flex;
  width: 100%;
  
  justify-content: center;
  height: 100%;

`
const MediaBody = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100% - 77px);
  width: 100%;
  overflow: hidden;
  @media ${device.mobile}{
    height: 100%;
    }

`

const FullScreenContainer = styled(FullScreen)`
  height: 100%;
`