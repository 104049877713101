import {useState, useContext} from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Trans } from 'react-i18next'
import SVG from "react-inlinesvg";
import IconChevronRight from '../img/icons/icon_chevron_right.svg'
import IconChevronDown from '../img/icons/icon_chevron_down.svg'
import { useTranslation } from 'react-i18next';
import {useLocation, useHistory} from 'react-router-dom'

const ExpandableSearchResults = ({edition, searchValue}) => {
    const [expanded, setExpanded] = useState(false)
    const {t} = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const themeContext = useContext(ThemeContext);
    
    const handleLink = (page) => {
        const type = getPageType(edition.productType)
        const goToPage = type === '/reader/' ? `?lbpage=${page + 1}` : ""
        history.push({pathname: type + edition.id, search: goToPage, state: { from: location.pathname, search: {value: searchValue, page: page} }})
    }

    const getPageType = (productType) => {
        switch (productType) {
            case "COURSE":
                return "/course/";
            case "AUDIO":
                return "/audio/";
            case "VIDEO":
                return "/video/";
            default:
                return "/reader/";

        }
    }

    return (
        <>
            <ResultText onClick={() => setExpanded(!expanded)}>
                <TextWrapper active={expanded}>
                <Trans i18nKey='searchsubresult' values={{ number: edition.contentItems.totalElements }}>
                            Treffer auf <strong>42</strong> Seiten
                        </Trans>
                </TextWrapper>

                        {
                            expanded ? <Icon src={IconChevronDown}/> : <Icon src={IconChevronRight}/>
                        }




            </ResultText>
            {
                expanded && 
                <ExpandableContent>
                    {
                        edition.contentItems.content.map((item, index) => (
                            <SubItem key={index} onClick={() => handleLink(item.itemId)}>
                                <SubItemtext dangerouslySetInnerHTML={{__html: "..." + item.description.replace(new RegExp(searchValue, "ig"), `<span style="color: ${themeContext.color.primary}">${searchValue}</span>`) + "..."}}>
                                </SubItemtext>
                                <SubItemPageNumber>
                                    {`${t('page')} ${item.title}`}
                                </SubItemPageNumber>

                            </SubItem>
                        ))
                    }

                    <StyledLink onClick={() => handleLink(edition.contentItems.content[0].itemId)}>
                        {t('show_all_results_in_edition')}
                    </StyledLink>


                </ExpandableContent>
            }
        </>
    )
}

export default ExpandableSearchResults

const ResultText = styled.span`
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`

const TextWrapper = styled.div<{active: boolean}>`
    color: ${props => props.active && props.theme.color.primary};
    font-weight: 500;
    font-size: 14px;
`

const ExpandableContent = styled.div`

`

const SubItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    &:hover {
        cursor: pointer;
        
    }
`

const SubItemtext = styled.span`
    font-size: 14px;
    line-height: 22px;
    &:hover {
        text-decoration: underline;
    }
`

const SubItemPageNumber = styled.span`
    margin-top: 2px;
    font-size: 12px;
    line-height: 22px;
    color: ${props => props.theme.color.grey_500};

`
const StyledLink = styled.div`
    color: ${props => props.theme.color.primary};
    display: flex;
    justify-content: center;
    margin-top: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    &:hover {
        color: ${props => props.theme.color.primaryHover}; 
        cursor: pointer;
    }
`

const Icon = styled(SVG)`
    margin-left: 4px;
    & path {
        fill: ${props => props.theme.color.primary}
    }
`